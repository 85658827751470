import React from "react";
import { HeroParallax } from "../../combonents/milan/Hero/Hero";
import { Outlet, Link } from "react-router-dom";
import { LayoutDashboard, ChevronRight } from "lucide-react";
import Header from "../../combonents/sa/header/header";
import Footer from "../../combonents/sa/footer/Footer";
interface Product {
  title: string;
  link: string;
  thumbnail: string;
  date: string;
}

const products: Product[] = [
  {
    title: "Trichy",
    link: "/milans/trichy",
    thumbnail: "/Trichy1.jpeg",
    date: "27th October, 2024",
  },
  {
    title: "Online Milans",
    link: "/milans/online",
    thumbnail: "/online.jpg",
    date: "12th-30th November, 2024",
  },
  {
    title: "Trivandrum",
    link: "/milans/trivandrum",
    thumbnail: "/trivandrum.jpeg",
    date: "16th December, 2024",
  },
 
  {
    title: "Bangalore",
    link: "/milans/bangalore",
    thumbnail: "/banglore.jpeg",
    date: "14th December, 2024",
  },

  {
    title: "Tirupati",
    link: "/milans/tirupati",
    thumbnail: "/Tirupati.jpg",
    date: "26th October, 2024",
  },
 
  {
    title: "Pondicherry",
    link: "/milans/pondicherry",
    thumbnail: "/pondicherry.jpg",
    date: "Coming Soon",
  },
];

const Milan: React.FC = () => {
  return (
    <div className="w-full relative">
      <Header/>
      <HeroParallax products={products} />
      <Outlet />
      <Link
        to="/milans/dashboard"
        className="fixed bottom-8 right-6 bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-4 px-8 rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105 flex items-center space-x-3 group text-lg"
      >
        <LayoutDashboard className="w-6 h-6" />
        <span>My Registrations</span>
        <ChevronRight className="w-5 h-5 transition-transform duration-300 group-hover:translate-x-1" />
      </Link>
      <Footer />
    </div>
  );
};

export default Milan;
