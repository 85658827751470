// Accommodation.tsx
import React from "react";
import { Building2 } from "lucide-react";
import Header from "../sa/header/header";
interface LoaderProps {
  className?: string;
}

const BouncingDotsLoader: React.FC<LoaderProps> = ({ className = "" }) => (
  <div className={`flex space-x-2 ${className}`}>
    <div className="w-3 h-3 bg-red-600 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
    <div className="w-3 h-3 bg-red-600 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
    <div className="w-3 h-3 bg-red-600 rounded-full animate-bounce"></div>
  </div>
);

const ProgressBar: React.FC<LoaderProps> = ({ className = "" }) => (
  <div
    className={`w-64 h-2 bg-gray-200 rounded-full overflow-hidden ${className}`}
  >
    <div className="w-full h-full bg-blue-600 animate-shimmer"></div>
  </div>
);

const CircularSpinner: React.FC<LoaderProps> = ({ className = "" }) => (
  <div
    className={`w-12 h-12 border-4 border-blue-200 border-t-blue-600 rounded-full animate-spin ${className}`}
  ></div>
);

interface AccommodationProps {
  title?: string;
  subtitle?: string;
}

const Accommodation: React.FC<AccommodationProps> = ({
  title = "Accommodation",
  subtitle = "Early bird <strong>Accommodation Combos</strong> available at sales.saarang.org",
}) => {
  return (
    <div className="min-h-screen bg-[#000319] p-8">
      <Header />
      <div className="max-w-4xl mx-auto bg-[#383d9b] rounded-xl shadow-lg p-8 mt-40">
        <div className="flex items-center space-    x-3 mb-12">
          <Building2 className="h-8 w-8 text-red-600" />
          <h1 className="text-2xl font-bold text-gray-100">{title}</h1>
        </div>

        <div className="flex flex-col items-center justify-center py-12 space-y-8">
          <BouncingDotsLoader />
          {/* <ProgressBar />
          <CircularSpinner /> */}

          <div className="text-center space-y-2">
            <p className="text-xl font-semibold text-gray-100">
              Accommodation Coming Soon
            </p>
            <a
              href="http://sales.saarang.org/#Combos"
              className="text-gray-200 hover:text-white font-medium transition duration-300 ease-in-out hover:scale-105 underline"
            >
              <p
                className="text-gray-200"
                dangerouslySetInnerHTML={{ __html: subtitle }}
              ></p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accommodation;
